<template>
  <b-modal
    id="add-coverage-table-modal"
    title="Add Coverage/Trace Table"
    size="lg"
    no-close-on-backdrop
    no-close-on-esc
    @ok="generateTable"
    @shown="onShown"
  >
    <b-row>
      <b-col>
        <div style="opacity: 0.8" class="mb-25">
          Select the Specification to report coverage to
        </div>
        <b-form-group>
          <SpecificationFormSelect
            :value="parentSpec"
            show-all
            @input="setParentSpec"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <div style="opacity: 0.8" class="mb-25">
          Select the Specification to report coverage from
        </div>
        <b-form-group>
          <SpecificationFormSelect
            :value="childSpec"
            show-all
            @input="setChildSpec"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <div style="opacity: 0.8" class="mb-25">
      Placement in relation to {{ requirement.display_id }}
    </div>
    <b-row>
      <b-col>
        <b-form-radio-group v-slot="{ ariaDescribedby }">
          <b-form-radio v-model="direction" :aria-describedby="ariaDescribedby" name="some-radios" value="before">
            Before
          </b-form-radio>
          <b-form-radio v-model="direction" :aria-describedby="ariaDescribedby" name="some-radios" value="after">
            After
          </b-form-radio>
          <b-form-radio v-model="direction" :aria-describedby="ariaDescribedby" name="some-radios" value="child">
            Child
          </b-form-radio>
        </b-form-radio-group>
      </b-col>
    </b-row>

    <template v-slot:modal-footer="{ok, cancel}">
      <b-button variant="outline-secondary" @click="cancel()">
        Dismiss
      </b-button>
      <b-button variant="success" :disabled="loading_status" @click="ok()">
        <span v-if="loading_status">
          <b-spinner small type="grow" />
          Generating...
        </span>
        <span v-else>
          Add Coverage/Trace Table
        </span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import coreService from '@/libs/api-services/core-service'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SpecificationFormSelect from '@/components/Specifications/Forms/Select.vue'

export default {
  name: 'AddCoverageTableModal',
  components: {
    SpecificationFormSelect,
  },
  props: {
    requirement: {
      type: Object,
      default: null,
    },
    spec: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      parentSpec: null,
      childSpec: null,
      loading_status: false,
      direction: 'after',
    }
  },
  methods: {
    onShown() {
    },
    generateTable(evt) {
      // This prevents the modal from closing prematurely
      evt.preventDefault()
      this.loading_status = true
      const payload = {
        model_id: this.$store.state.model.id,
        spec_id: this.spec,
        parent: this.parentSpec,
        child: this.childSpec,
        target_node: this.requirement.id,
        relation: this.direction,
      }
      this.$http
        .post('/v1/legacy/specifications/generate_coverage_table', payload)
        .then(({ data }) => {
          this.loading_status = false
          this.$bvModal.hide('add-coverage-table-modal')
          this.$store.dispatch('requirementsTableLegacy/getData', { requirements: [data.id], setLayout: true }, { root: true })
          this.$store.dispatch('requirements/selectRequirement', data.id)
        })
    },
    setParentSpec(id) {
      this.parentSpec = id
    },
    setChildSpec(id) {
      this.childSpec = id
    },
    cancel() {
      this.loading_status = false
    },
  },
}
</script>

<style lang="scss">
@import 'src/assets/scss/variables/_variables.scss';
.v-select-style-overrides {
  .vs__dropdown-toggle, .vs__dropdown-menu {
    text-transform: capitalize;
  }
}

.dark-layout {
  .vs__dropdown-menu {
    background: yellow;
    li {
      color: red;
    }
  }

  .v-select-style-overrides {
    .vs__dropdown-option--selected {
      background: lighten($info, 0.5%);
    }
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
