<template>
  <form-wizard
    color="#F7991C"
    :title="null"
    :subtitle="null"
  >
    <!-- ********************************* REQUIREMENT ********************************* -->
    <tab-content
      title="Requirement"
      icon="feather icon-edit"
      class="px-2 mb-4"
    >
      <validation-observer
        ref="accountRules"
        tag="form"
      >
        <b-row class="mb-2">
          <b-col>
            <div class="w-100 d-inline-flex justify-content-between">
              <LabelWithHoverIcon
                class="flex-grow-1 mr-2"
                label-string="Requirement Definition"
                popover-text="The Requirement itself, 'Use Case' format is preferred"
              />
              <div class="d-inline-flex align-items-center mb-50">
                <b-form-checkbox
                  :checked="expandRequirementDescription"
                  switch
                  class="custom-control-info"
                  @change="expandRequirementDescription = !expandRequirementDescription"
                >
                  <span class="switch-icon-left">
                    <feather-icon
                      icon="Minimize2Icon"
                      class="cursor-pointer"
                    />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon
                      icon="Maximize2Icon"
                      class="cursor-pointer"
                    />
                  </span>
                </b-form-checkbox>
                <span v-if="expandRequirementDescription" class="font-small-3">
                  Collapse
                </span>
                <span v-else class="font-small-3">
                  Expand
                </span>
              </div>
            </div>
            <validation-provider
              #default="{ errors }"
              name="requirement"
              rules=""
            >
              <tip-tap-editor
                v-model="object_text"
                :state="errors.length > 0 ? false : null"
                placeholder="Compose a requirement..."
                min-height="20"
                :max-height="expandRequirementDescription ? '' : '20'"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>

        <!--SECTION and PRIORITY-->
        <b-row>
          <b-col>
            <b-form-group>
              <LabelWithHoverIcon
                label-string="Priority"
                popover-text="The priority level of the Requirement for the project."
              />

              <b-form-select
                v-model="priority"
                :options="priority_options"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <LabelWithHoverIcon
                label-string="Security Classification"
                popover-text="Australian Government PSF Security classification. Unclassified (U) by default."
              />
              <b-form-select
                v-model="classification"
                :options="security_classification_options"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="auth.roles.includes('administrator')">
          <b-col>
            <permissions title="Permissions" node-type="Requirement" :node-name="selected_requirement.properties.display_id" :node-id="selected_requirement.properties.id" />
          </b-col>
        </b-row>
      </validation-observer>
    </tab-content>

    <!-- ********************************* ADDITIONAL ATTRIBUTES ********************************* -->
    <tab-content
      title="Additional Attributes"
      icon="feather icon-plus-circle"
      class="px-2 mb-4"
    >
      <b-form>
        <b-form-group>
          <LabelWithHoverIcon
            label-string="Add new attribute"
            popover-text="Add an attribute for this Requirement."
          />
          <b-input-group>
            <b-form-input
              v-model="new_additional_attribute_field"
              :state="validAttribute(new_additional_attribute_field).isValid || new_additional_attribute_field === '' ? null : false"
            />
            <b-input-group-append>
              <b-button
                variant="outline-success"
                :disabled="validAttribute(new_additional_attribute_field).isValid === false"
                @click="addNewAttribute(new_additional_attribute_field)"
              >
                <feather-icon icon="PlusIcon" />
                Add new Attribute
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <small v-if="validAttribute(new_additional_attribute_field).isValid === false">
            <strong>{{ new_additional_attribute_field }}</strong>
            {{ validAttribute(new_additional_attribute_field).msg }}
          </small>
        </b-form-group>
        <hr>
        <!--  SPECIFICATIONS ADDITIONAL ATTRIBUTES -->
        <LabelWithHoverIcon
          label-string="Existing Attributes"
          popover-text="Attributes inherited from the parent Specification"
        />
        <b-table
          v-if="additional_attributes.length"
          responsive
          striped
          hover
          show-empty
          :items="additional_attributes"
          :fields="additional_attributes_fields"
        >
          <template #cell(value)="{ item }">
            <b-input-group>
              <b-form-input v-model="item.value" size="sm" />
              <b-input-group-append v-if="!specifications_additional_attributes.includes(item.field)">
                <b-button
                  variant="outline-danger"
                  @click="additional_attributes = additional_attributes.filter(({ field }) => field !== item.field)"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </template>
        </b-table>
        <h4 v-else style="text-align: center">
          No Additional Attributes
        </h4>
      </b-form>
    </tab-content>

    <template slot="footer" slot-scope="props">
      <div class="px-2">
        <div class="wizard-footer-left">
          <wizard-button
            v-if="props.activeTabIndex > 0"
            class="wizard-footer-right"
            :style="props.fillButtonStyle"
            @click.native="props.prevTab()"
          >
            Back
          </wizard-button>
        </div>
        <div class="wizard-footer-right">
          <!--<LabelWithHoverIcon-->
          <!--  v-if="mode === 'add'"-->
          <!--  popover-text="Adding and parsing a Requirement will create entities (performers, functions, objects, and objectives) along with their relations from the requirement text.-->
          <!--  <br>-->
          <!--  <br>-->
          <!--  Adding a Requirement without parsing it will simply add it to the Specification without creating any entities or relationships."-->
          <!--  custom-size="20"-->
          <!--  popover-direction="left"-->
          <!--  class="float-left mt-50 mr-1"-->
          <!--/>-->
          <!--<b-button-->
          <!--  v-if="mode === 'add'"-->
          <!--  variant="success"-->
          <!--  class="py-1 mr-1"-->
          <!--  @click="$emit('add-with-parse')"-->
          <!--&gt;-->
          <!--  Create & Parse-->
          <!--</b-button>-->
          <b-button
            v-if="mode === 'add'"
            variant="success"
            class="py-1 mr-1"
            @click="$emit('add-without')"
          >
            Create Requirement
          </b-button>
          <wizard-button v-if="!props.isLastStep" class="wizard-footer-right" :style="props.fillButtonStyle" @click.native="props.nextTab()">
            Next
          </wizard-button>
        </div>
      </div>
    </template>
  </form-wizard>
</template>

<script>
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import LabelWithHoverIcon from '@/components/Forms/LabelWithHoverIcon.vue'
import TipTapEditor from '@/components/Forms/TipTapEditor/TipTapEditor.vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapGetters, mapState } from 'vuex'
import Permissions from '@/components/Permissions/Permissions.vue'
import coreService from '@/libs/api-services/core-service'

export default {
  name: 'RequirementAddEditFormFields',
  components: {
    FormWizard,
    LabelWithHoverIcon,
    TabContent,
    TipTapEditor,
    ValidationObserver,
    ValidationProvider,
    Permissions,
  },
  props: {
    mode: {
      type: String,
      required: true,
    },
    newDefault: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      expandRequirementDescription: false,
      spec_id: null,
      priority: '',
      classification: '',
      object_text: '',
      specifications_additional_attributes: [],
      specifications_attributes: [],
      additional_attributes: [],
      additional_attributes_fields: [
        {
          key: 'field',
          label: 'Additional Attributes',
        },
        {
          key: 'value',
          label: 'Value',
        },
      ],
      new_additional_attribute_field: '',
      req_options: [],
    }
  },
  computed: {
    ...mapState({
      selected_requirement: state => state.requirements.selected_requirement,
      selected_specification: state => state.requirementsTableLegacy.specification,
      auth: state => state.auth,
    }),
    ...mapGetters({
      priority_options: 'constants/requirementPriorities',
      security_classification_options: 'constants/securityClassifications',
      protectedReqAttributes: 'constants/protectedReqAttributes',
    }),
  },
  watch: {
    selected_specification: {
      immediate: true,
      handler(newSpec) {
        if (newSpec) {
          this.spec_id = this.selected_specification
        }
      },
    },
    spec_id: {
      immediate: true,
      handler(newSpec) {
        if (newSpec) {
          this.getAdditionalAttributes()
        }
      },
    },
  },
  mounted() {
    this.classification = this.$store.state.model.defaultSecurityClassification
    this.priority = this.$store.state.constants.defaultRequirementPriority.id
    this.$watch(
      () => this.selected_requirement,
      (newRequirement, previousRequirement) => {
        this.setFields()
      },
    )
  },
  methods: {
    modalOpened() {
      this.setFields()
      if (this.mode === 'add' || !this.selected_requirement.properties.priority) {
        this.priority = this.$store.state.constants.defaultRequirementPriority.id
      }
    },
    setFields() {
      const { properties } = this.selected_requirement
      if (this.mode !== 'add') {
        this.object_text = properties.object_text
        const standardFields = ['priority', 'spec_id', 'classification', 'requirement']
        standardFields.forEach(field => {
          if (properties[field]) this[field] = properties[field]
        })
      }

      const params = { model: this.$store.state.model.id }
      this.protected_attributes = this.protectedReqAttributes.map(attr => attr.value)

      const headers = {
        'model-id': this.$store.state.model.id,
      }
      this.additional_attributes = []
      if (!this.spec_id) {
        this.spec_id = this.$route.params.specId
      }
      if (this.mode === 'add') {
        coreService
          .get(`/v1/specifications/${this.spec_id}/custom_properties`, { headers })
          .then(({ data }) => {
            Object.keys(data).forEach(key => {
              if (!this.additional_attributes.find(({ field }) => field === key)) {
                this.additional_attributes.push({ field: key, value: data[key] })
              } else {
                this.additional_attributes.find(({ field }) => field === key).value = data[key]
              }
            })
          })
      }
      this.$http
        .get(`/v1/legacy/specifications/additional_attributes/${this.spec_id}`, { params })
        .then(({ data }) => {
          data.forEach(property => {
            if (this.mode === 'add') {
              if (!this.additional_attributes.find(({ field }) => field === property)) {
                this.additional_attributes.push({ field: property, value: '' })
              } else {
                this.additional_attributes.find(({ field }) => field === property).value = ''
              }
            }
            if (this.mode !== 'add') {
              if (!this.additional_attributes.find(({ field }) => field === property)) {
                this.additional_attributes.push({ field: property, value: properties[property] })
              } else {
                this.additional_attributes.find(({ field }) => field === property).value = properties[property]
              }
            }
          })
        })
    },
    getFields() {
      const addAttr = {}
      this.additional_attributes.forEach(attr => {
        if (attr.value) {
          addAttr[attr.field] = attr.value
        }
      })
      return {
        priority: this.priority,
        spec_id: (this.newDefault ? this.newDefault : this.spec_id),
        classification: this.classification,
        object_text: this.object_text,
        new_attrs: addAttr,
      }
    },
    getAdditionalAttributes() {
      const params = {
        model: this.$store.state.model.id,
      }
      this.$http.get(`/v1/legacy/specifications/additional_attributes/${this.spec_id}`, { params }).then(({ data }) => {
        this.specifications_additional_attributes = data
        this.additional_attributes = data.map(field => Object({
          field,
          value: '',
        }))
      })
      this.$http.get(`/v1/legacy/specifications/attributes/${this.spec_id}`, { params }).then(({ data }) => {
        this.specifications_attributes = data
      })
    },
    addNewAttribute(field) {
      this.additional_attributes.push({
        field,
        value: '',
      })
      this.new_additional_attribute_field = ''
    },
    validAttribute(field) {
      let msg = ''
      let isValid = false
      if (field === '') {
        msg = 'field can not be empty'
      } else if (this.protected_attributes.includes(field)) {
        msg = 'is a protected requirement field'
      } else if (this.additional_attributes.map(({ field }) => field).includes(field)) {
        msg = 'is already a requirement field'
      } else {
        isValid = true
      }
      return {
        isValid,
        msg,
      }
    },
  },
}
</script>

<style lang="scss">
body.dark-layout .vue-form-wizard .wizard-navigation .wizard-nav li.active a .wizard-icon-circle .wizard-icon {
  color: white !important;
}
.vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked {
  box-shadow: 0 3px 6px 0 rgba(247, 153, 28, 0.4) !important;
}
</style>
