<template>
  <b-modal
    id="move-requirement-modal"
    :title="`Move Requirement ${sourceRequirement.properties.display_id}`"
    size="lg"
    class="p-0"
    lazy
    no-close-on-esc
    no-close-on-backdrop
    @show="onShow"
    @ok.prevent="onSubmit"
  >
    <div>
      <h4>
        Moving Requirement
        <span class="text-primary">{{ sourceRequirement.properties.display_id }}</span>
      </h4>
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="scroll-area-idea-entities"
        style="max-height: 10rem"
      >
        <div class="font-small-3 ml-1" v-sanitized-html="sourceRequirement.properties.object_text" />
      </vue-perfect-scrollbar>

      <h6 class="mt-2 text-primary">
        1. Select target Specification to Move to
      </h6>
      <SpecificationPicker v-model="targetSpecification" />

      <h6 class="mt-2 text-primary">
        2. Select target Requirement
      </h6>
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="scroll-area-idea-entities"
        style="max-height: 20rem"
      >
        <b-table
          responsive
          striped
          hover
          selectable
          show-empty
          select-mode="single"
          class="position-relative"
          :fields="requirementTableFields"
          :items="requirementsList"
          @row-selected="onRowSelected"
        />
      </vue-perfect-scrollbar>

      <h6 class="mt-2 text-primary">
        3. Relationship to target Requirement
      </h6>
      <b-row>
        <b-col>
          <b-form-radio-group v-slot="{ ariaDescribedby }">
            <b-form-radio v-model="relationToTarget" :aria-describedby="ariaDescribedby" name="some-radios" value="before">
              Before
            </b-form-radio>
            <b-form-radio v-model="relationToTarget" :aria-describedby="ariaDescribedby" name="some-radios" value="after">
              After
            </b-form-radio>
            <b-form-radio v-model="relationToTarget" :aria-describedby="ariaDescribedby" name="some-radios" value="child">
              Child
            </b-form-radio>
          </b-form-radio-group>
        </b-col>
      </b-row>

      <hr>
      <h5 v-if="targetRequirement" class="text-center mt-1">
        <span class="text-primary">Move</span>
        requirement
        <span class="text-primary"> {{ sourceRequirement.properties.display_id }}</span>
        <span class="text-danger text-uppercase"> - {{ relationToTarget === 'child' ? 'as a child below' : relationToTarget }} - </span>
        <span class="text-primary">{{ targetRequirement.display_id }}</span>
      </h5>
    </div>

    <!-- Buttons -->
    <template v-slot:modal-footer="{ ok, cancel}">
      <b-button
        :disabled="loading"
        variant="outline-secondary"
        @click="cancel"
      >
        Discard
      </b-button>
      <b-button variant="success" :disabled="loading || !targetRequirement" @click="onSubmit">
        <span v-if="loading">
          <b-spinner small type="grow" />
          Moving...
        </span>
        <span v-else>
          Move Requirement
        </span>
      </b-button>
    </template>
    <!-- ./Buttons -->
  </b-modal>
</template>

<script>
import store from '@/store'
import coreService from '@/libs/api-services/core-service'
import { computed, ref, watch } from '@vue/composition-api'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import SpecificationPicker from '@/components/Specifications/SpecificationPicker.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'MoveRequirementsModal',
  components: {
    VuePerfectScrollbar,
    SpecificationPicker,
  },
  setup(props, context) {
    const loading = ref(false)
    const perfectScrollbarSettings = { maxScrollbarLength: 60 }
    const requirementTableFields = [
      { key: 'section', label: 'Section' },
      { key: 'display_id', label: 'Display ID' },
      { key: 'priority', label: 'Priority' },
    ]

    const sourceRequirement = computed(() => store.state.requirements.selected_requirement)
    const targetSpecification = ref(null)
    const targetRequirement = ref(null)
    const relationToTarget = ref('after')

    const requirementsList = ref([])
    watch(targetSpecification, val => {
      requirementsList.value = []
      if (targetSpecification.value !== null) {
        coreService
          .get(`/v1/legacy/specifications/${targetSpecification.value.id}/requirements`)
          .then(({ data }) => {
            Object.entries(data).forEach(entry => {
              requirementsList.value.push(entry[1])
            })
          })
      }
    })

    const onShow = () => {
      targetSpecification.value = null
      targetRequirement.value = null
      relationToTarget.value = 'after'
    }

    const onRowSelected = rows => {
      targetRequirement.value = rows[0]?.id ? rows[0] : null
    }

    const onSubmit = () => {
      loading.value = true
      const payload = {
        requirement_ids: [sourceRequirement.value.properties.id],
        target_relation_type: relationToTarget.value,
        target_requirement: targetRequirement.value.id,
      }

      store
        .dispatch('requirements/moveRequirement', payload)
        .then(response => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Moved Requirement',
              text: sourceRequirement.value.properties.display_id,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          context.root.$bvModal.hide('move-requirement-modal')
          context.emit('ok')
        })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      loading,
      perfectScrollbarSettings,
      requirementTableFields,
      requirementsList,

      sourceRequirement,
      targetSpecification,
      targetRequirement,
      relationToTarget,

      onShow,
      onRowSelected,
      onSubmit,
    }
  },
}
</script>
