<template>
  <b-modal
    id="snapshot-compare-modal"
    title="Compare current Specification with Snapshot"
    size="lg"
    hide-footer
  >
    <specification-select
      v-model="specification"
      class="mb-2"
    />

    <b-table
      v-if="!loading && snapshots.length"
      :items="snapshots"
      :fields="tableFields"
    >
      <template #cell(created)="{ value }">
        <div>{{ value | diffForHumans }}</div>
      </template>

      <template #cell(actions)="{ value }">
        <b-button
          variant="outline-primary"
          size="sm"
          @click="openSnapshot(value)"
        >
          Compare with Snapshot
        </b-button>
      </template>
    </b-table>

    <div v-else-if="loading && specification">
      <div class="w-100 d-flex justify-content-center mb-2">
        <b-spinner variant="primary" />
      </div>
      <div class="w-100 text-center">
        <h4>
          Loading snapshots for Specification
          <span class="text-primary font-weight-bold">{{ getSpecificationById(specification).title }}</span>
        </h4>
      </div>
    </div>
    <h4
      v-else-if="specification"
      class="ml-1 mt-1 mb-2"
    >
      Specification
      <span class="text-primary font-weight-bold">{{ getSpecificationById(specification).title }}</span>
      has no snapshots.
    </h4>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex'
import SpecificationSelect from '@/components/Specifications/Forms/Select.vue'

export default {
  name: 'SnapshotCompare',
  components: {
    SpecificationSelect,
  },
  data() {
    return {
      loading: false,
      specification: null,
      snapshots: [],
      tableFields: [
        { key: 'notes', label: 'Snapshot' },
        { key: 'created', label: 'Created' },
        { key: 'created_by', label: 'Created By' },
        { key: 'actions', label: '' },
      ],
    }
  },
  watch: {
    specification(specId) {
      this.loading = true
      const params = { model: this.$store.state.model.id }
      this.$http
        .get(`/v1/legacy/specifications/${specId}/snapshots`, { params })
        .then(({ data }) => { this.snapshots = data })
        .finally(() => { this.loading = false })
    },
  },
  methods: {
    ...mapActions({
      setMode: 'requirementsTableLegacy/setMode',
    }),
    openSnapshot(id) {
      this.setMode({ mode: 'snapshot_compare', snapshotId: id })
      this.$bvModal.hide('snapshot-compare-modal')
    },
  },
}
</script>
