<template>
  <b-modal
    id="edit-requirement-modal"
    :title="`Editing Requirement: ${selected_requirement.properties.display_id}`"
    ok-title="Save Changes"
    ok-variant="success"
    cancel-title="Discard Changes"
    cancel-variant="outline-secondary"
    size="xl"
    @shown="$refs.edit_fields.setFields()"
    @ok="editRequirement()"
  >
    <span v-if="selected_requirement.parent && selected_requirement.parent.id">
      <b-link :title="selected_requirement.parent.object_text"
              @click="changeRequirement(selected_requirement.parent.id)"
      >{{ selected_requirement.parent.display_id }}</b-link>
      /
    </span>
    <span> {{ selected_requirement.properties.display_id }} </span>
    <span v-for="(cr, index) in selected_requirement.children" :key="cr.id">
      <span v-if="index === 0">/</span>
      <b-link :title="cr.object_text" @click="changeRequirement(cr.id)"> {{ cr.display_id }}</b-link>
      <span v-if="index < selected_requirement.children.length - 1">,</span>
    </span>
    <requirement-form-fields
      ref="edit_fields"
      :requirement="requirement_object"
      mode="edit"
    />
  </b-modal>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import RequirementFormFields from '@/views/RequirementsTableLegacy/forms/Fields.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import coreService from '@/libs/api-services/core-service'

export default {
  name: 'EditRequirement',
  components: {
    RequirementFormFields,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      requirement_object: {},
    }
  },
  computed: {
    ...mapState({
      selected_requirement: state => state.requirements.selected_requirement,
      model: state => state.model.id,
    }),
  },
  methods: {
    ...mapActions({
      editRequirement_vuex: 'requirements/editRequirement',
    }),
    populateFields() {
      this.$refs.edit_fields.initializeForm()
      const requirementId = this.id ? this.id : this.selected_requirement.properties.id
      const params = {
        model: this.model,
      }
      coreService.get(`/v1/legacy/requirements/${requirementId}`, { params }).then(({ data }) => {
        data.trace = data.traceability.split(',')
        this.requirement_object = data
      })
    },
    changeRequirement(requirementId) {
      // Change the current requirement when navigating to parent or child
      if (requirementId !== this.id) {
        this.$store.dispatch('requirements/selectRequirement', requirementId)
      }
    },
    editRequirement() {
      const requirementId = this.id ? this.id : this.selected_requirement.properties.id
      this.editRequirement_vuex({ id: requirementId, requirement: this.$refs.edit_fields.getFields() })
        .then(() => {
          this.$store.dispatch('requirements/selectRequirement', requirementId)
          this.$bvModal.hide('edit-requirement-modal')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Requirement updated',
              icon: 'EditIcon',
              text: this.selected_requirement.properties.display_id,
              variant: 'success',
            },
          })
        })
    },
  },
}
</script>
