<template>
  <b-modal
    id="export-requirements-modal"
    title="Export Requirements to file"
    size="xl"
    no-close-on-backdrop
    no-close-on-esc
    @ok="exportRequirements"
    @show="onShow"
  >
    <div v-if="loading">
      <div class="modal-body">
        <h1 class="mt-4 font-medium-3 text-center">
          Loading Export Dropdown...
        </h1>
        <h5 class="text-center text-warning">
          <b-spinner />
        </h5>
      </div>
    </div>
    <div v-else>
      <div style="opacity: 0.8">
        Export format
      </div>
      <b-row class="mt-50">
        <b-col cols="6">
          <b-form-radio-group v-model="file_format" class="ml-25">
            <b-form-radio value="xlsx">
              CSV/XLSX
            </b-form-radio>
            <b-form-radio value="json">
              JSON
            </b-form-radio>
            <b-form-radio value="docx">
              DOCX
            </b-form-radio>
            <b-form-radio value="pdf">
              PDF
            </b-form-radio>
          </b-form-radio-group>
        </b-col>
        <b-col cols="6">
          <b-form-checkbox
            v-model="show_deleted"
            class="custom-control-danger"
          >
            Include deleted requirements
          </b-form-checkbox>
        </b-col>
      </b-row>

      <br>
      <b-row v-if="file_format === 'docx'" class="mb-1">
        <b-col>
          <label>Select a DOCX template</label>
          <b-form-select
            v-model="selected_docx_template"
            :options="docx_templates"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <div style="opacity: 0.8" class="mb-25">
            Select a Specification to export
          </div>
          <b-form-group>
            <SpecificationFormSelect
              :value="spec"
              show-all
              @input="changeSpecification"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <div style="opacity: 0.8" class="mb-25">
            Export columns
          </div>
          <v-select
            v-if="!(file_format === 'pdf' || (file_format === 'docx' && document_layout === 'paragraph'))"
            v-model="selected_columns"
            multiple
            label="title"
            class="v-select-style-overrides"
            :options="all_columns"
          >
            <template #option="{ title }">
              <span class="text-dark">
                {{ title }}
              </span>
            </template>
          </v-select>

          <div v-else>
            <ul>
              <li>Section</li>
              <li>Display ID</li>
              <li>Requirement</li>
              <li>Priority</li>
            </ul>
          </div>
        </b-col>
      </b-row>
    </div>

    <template v-slot:modal-footer="{ok, cancel}">
      <b-button variant="outline-secondary" @click="cancel()">
        Dismiss
      </b-button>
      <b-button variant="success" :disabled="loading_status" @click="ok()">
        <span v-if="loading_status">
          <b-spinner small type="grow" />
          Exporting...
        </span>
        <span v-else>
          Export Requirements
        </span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import coreService from '@/libs/api-services/core-service'
import vSelect from 'vue-select'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SpecificationFormSelect from '@/components/Specifications/Forms/Select.vue'

// eslint-disable-next-line import/no-extraneous-dependencies
const FileSaver = require('file-saver')

export default {
  name: 'ExportRequirementsModal',
  components: {
    vSelect,
    SpecificationFormSelect,
  },
  data() {
    return {
      spec: null,
      file: '',
      loading_status: false,
      document_layout: 'paragraph',
      file_format: 'xlsx',
      show_deleted: false,
      all_columns: [],
      selected_columns: ['display_id', 'object_text', 'priority'],
      docx_templates: [],
      selected_docx_template: null,
      loading: false,
    }
  },
  methods: {
    onShow() {
      this.loading = true
      const { columns, specification, show_deleted } = this.$store.state.requirementsTableLegacy
      this.selected_columns = columns || this.selected_columns
      this.spec = specification || this.spec
      // eslint-disable-next-line camelcase
      this.show_deleted = show_deleted || this.show_deleted

      const params = { model: this.$store.state.model.id }
      coreService.get('/v1/legacy/requirements/attributes', { params }).then(({ data }) => {
        const { attrs } = data
        const nonVisibleCols = ['old_id', 'parent_rel']
        this.all_columns = attrs.filter(attr => !nonVisibleCols.includes(attr))
      })
      coreService.get('/v1/legacy/requirements/docx_templates').then(({ data }) => {
        this.docx_templates = data
        if (data.length) {
          // eslint-disable-next-line prefer-destructuring
          this.selected_docx_template = data[0]
        }
      })
      this.loading = false
    },
    changeSpecification(id) {
      // Get the ID when you change in the dropdown
      this.spec = id
    },
    exportRequirements(evt) {
      const modelId = this.$store.state.model.id
      // This prevents the modal from closing prematurely
      evt.preventDefault()
      if (this.file_format === 'pdf') {
        this.$bvModal.hide('export-requirements-modal')
        this.$store.dispatch('specifications/selectSpecification', this.spec)
        this.$router.push(
          {
            name: 'export_specifications',
            params: { id: this.spec },
          },
        )
      } else {
        // Show loading circle and button to users
        this.loading_status = true
        const params = {
          model: modelId,
        }
        // Use datetime to make the file unique
        let now = new Date()
        now = moment(now).format('MMM_DD_HH_MM_SS')
        // Create and append data to the json file
        const filename = `requirements_export_${now}.${this.file_format}`
        if (this.file_format === 'json') {
          let urlSuffix = '/v1/legacy/requirements'
          if (this.spec != null) {
            urlSuffix = `${urlSuffix}/for_spec/${this.spec}`
          }
          // Defaulted the timeout at 3 mins - can alter
          coreService.get(urlSuffix, { params }, { timeout: 180000 })
            .then(response => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Requirements exported',
                  icon: 'CheckIcon',
                  variant: 'success',
                  text: '',
                },
              })
              // Download the generated file
              const { data } = response
              const text = JSON.stringify(data)
              const element = document.createElement('a')
              element.setAttribute('href', `data:application/json;charset=utf-8,${encodeURIComponent(text)}`)
              element.setAttribute('download', filename)
              element.style.display = 'none'
              document.body.appendChild(element)
              element.click()
              document.body.removeChild(element)
              // Clear the loading and close the modal
              this.loading_status = false
              this.$bvModal.hide('export-requirements-modal')
            })
            .catch(response => {
              console.error(response.data)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error exporting Requirement',
                  icon: 'CheckIcon',
                  variant: 'danger',
                  text: `Failed to export Requirement (${response.data})`,
                },
              })
              this.loading_status = false
              this.$bvModal.hide('export-requirements-modal')
            })
        } else if (this.file_format === 'xlsx') {
          this.loading_status = true
          coreService.post('/v1/legacy/requirements/export', {
            file_type: this.file_format,
            columns: this.selected_columns,
            specification: this.spec,
            show_deleted: this.show_deleted,
            template: this.document_layout,
            docx_template: this.selected_docx_template,
          }, { params, responseType: 'blob' }).then(({ data }) => {
            FileSaver.saveAs(data, filename)
            this.loading_status = false
            this.$bvModal.hide('export-requirements-modal')
          })
            .catch(response => {
              console.error(response.data)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error exporting Requirement',
                  icon: 'CheckIcon',
                  variant: 'danger',
                  text: `Failed to export Requirement (${response.data})`,
                },
              })
              this.loading_status = false
              this.$bvModal.hide('export-requirements-modal')
            })
        } else {
          this.loading_status = true
          if (this.selected_docx_template.toLowerCase().includes('table')) {
            this.file_format = 'docxt'
          }
          coreService.post('/v1/legacy/requirements/export', {
            file_type: this.file_format,
            columns: this.selected_columns,
            specification: this.spec,
            show_deleted: this.show_deleted,
            template: this.document_layout,
            docx_template: this.selected_docx_template,
          }, { params, responseType: 'blob' }).then(({ data }) => {
            FileSaver.saveAs(data, filename)
            this.loading_status = false
            this.$bvModal.hide('export-requirements-modal')
          })
            .catch(response => {
              console.error(response.data)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error exporting Requirement',
                  icon: 'CheckIcon',
                  variant: 'danger',
                  text: `Failed to export Requirement (${response.data})`,
                },
              })
              this.loading_status = false
              this.$bvModal.hide('export-requirements-modal')
            })
        }
      }
    },
    cancel() {
      this.loading_status = false
    },
  },
}
</script>

<style lang="scss">
@import 'src/assets/scss/variables/_variables.scss';
.v-select-style-overrides {
  .vs__dropdown-toggle, .vs__dropdown-menu {
    text-transform: capitalize;
  }
}

.dark-layout {
  .vs__dropdown-menu {
    background: yellow;
    li {
      color: red;
    }
  }

  .v-select-style-overrides {
    .vs__dropdown-option--selected {
      background: lighten($info, 0.5%);
    }
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
