<template>
  <div>
    <b-row class="mt-75">
      <b-col>
        <list-group-entity
          label="Qualification Records"
          :is-create="true"
          :entity-array="associatedRecords"
          modal="create-qualification-record-modal"
        >
          <template #icon>
            <feather-icon icon="PlusIcon" />
          </template>
          <template #body="{ array }">
            <record-list-group-item
              v-for="(record, index) in array"
              :key="index"
              :records-item="record"
              @update-qualification-record="openUpdateQrModal"
              @delete-qualification-record="openDeleteQrModal"
              @link-evidence-with-qualification-record="openLinkEvidenceModal"
            />
          </template>
        </list-group-entity>
      </b-col>
    </b-row>
    <hr>
    <b-row class="mt-75">
      <b-col>
        <list-group-entity
          label="Actors/Performers"
          :entity-array="fields.selected_performers"
          modal="allocate-req-performers-modal"
        />
      </b-col>
    </b-row>
    <b-row class="mt-75">
      <b-col>
        <list-group-entity
          label="Enablers"
          :entity-array="fields.selected_enablers"
          modal="allocate-req-enablers-modal"
        />
      </b-col>
    </b-row>
    <b-row class="mt-75">
      <b-col>
        <list-group-entity
          label="Beneficiaries"
          :entity-array="fields.selected_beneficiaries"
          modal="allocate-req-beneficiaries-modal"
        />
      </b-col>
    </b-row>
    <b-row class="mt-75">
      <b-col>
        <list-group-entity
          label="Functions"
          :entity-array="fields.selected_functions"
          modal="allocate-req-functions-modal"
        />
      </b-col>
    </b-row>
    <b-row class="mt-75">
      <b-col>
        <list-group-entity
          label="Interfaces"
          :entity-array="fields.selected_interfaces"
          modal="allocate-req-interfaces-modal"
        />
      </b-col>
    </b-row>
    <b-row class="mt-75">
      <b-col>
        <list-group-entity
          label="Objectives"
          :entity-array="fields.selected_objectives"
          modal="allocate-req-objectives-modal"
        />
      </b-col>
    </b-row>
    <b-row class="mt-75">
      <b-col>
        <list-group-entity
          label="Standards/References"
          :entity-array="fields.selected_standards"
          modal="allocate-req-standards-modal"
        />
      </b-col>
    </b-row>
    <b-row class="mt-75">
      <b-col>
        <list-group-entity
          label="Resources/Information/Data/Other"
          :entity-array="fields.selected_allocations"
          modal="allocate-req-allocations-modal"
        />
      </b-col>
    </b-row>

    <hr>

    <b-row>
      <b-col>
        <list-group-releases
          label="Releases"
          :entity-array="fields.selected_releases"
          modal="allocate-req-release-modal"
        />
      </b-col>
    </b-row>

    <!-- Modals -->
    <create-qualification-record-modal :parent-id="selected_requirement.properties.id" />
    <div v-if="selectedQualificationRecordId">
      <update-qualification-record-modal :qr-id="selectedQualificationRecordId" />
      <delete-qualification-record-modal :qr-id="selectedQualificationRecordId" />
      <link-evidence-with-qualification-record :qr-id="selectedQualificationRecordId" />
    </div>

    <!-- Associators -->
    <div v-for="assc in associators" :key="assc.singular">
      <GenericAssociator
        :id="`allocate-req-${getPlural(assc)}-modal`"
        :fetch-filter-fn="fetchSharedModels"
        :fetch-fn="modelId => fetchComponents(modelId, `${getLeftOpts(assc)}`)"
        :update-fn="payload => linkComponent(payload, assc.singular.toLowerCase())"
        :remove-fn="payload => unlinkComponent(payload)"
        :initial-prefilter="selectedModel"
        :initial-list="fields[`selected_${getPlural(assc)}`]"
        :filter-properties="['aliases']"
        prefilter-label="name"
        :type-name="assc.label"
      >
        <template #left="cpt">
          <b-badge v-for="l in cpt.labels.filter(lb => lb !== 'Component')" :key="l" class="mr-1" variant="primary">
            ({{ l }})
          </b-badge>
          <span :title="cpt.name" class="mr-1 text-ellipsis">{{ cpt.name }}  {{ cpt.aliases.join(', ') }} </span>
        </template>
        <template #right="{ cpt, triggerSave }">
          <ComponentAllocatedListItem :component-badge="assc.singular" :component="cpt"
                                      :trigger-fn="triggerSave"
          />
        </template>
      </GenericAssociator>
    </div>
    <Associator
      id="allocate-req-release-modal"
      title="Allocate Requirement to Releases"
      left-label="Releases"
      :left-opts="releases.map(x=>{return{value:x.id,text:x.name}})"
      right-label="Releases"
      :right-opts="fields.selected_releases.map(x=>{return{value:x.id,text:x.name}})"
      @ok="linkReqReleases"
    />
  </div>
</template>

<script>
import { nextTick } from '@vue/composition-api'
import { mapGetters, mapState } from 'vuex'
import { BCol, BRow } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Associator from '@/components/Forms/M_Associator.vue'
import ListGroupEntity from '@/components/Forms/ListGroups/ListGroupEntity.vue'
import ListGroupReleases from '@/components/Forms/ListGroups/ListGroupRelease.vue'
import RecordListGroupItem from '@/views/OQE/RecordsModals/RecordListGroupItem.vue'
import CreateQualificationRecordModal from '@/components/ObjectiveQualificationEvidence/Record/CreateQualificationRecordModal.vue'
import UpdateQualificationRecordModal from '@/components/ObjectiveQualificationEvidence/Record/UpdateQualificationRecordModal.vue'
import DeleteQualificationRecordModal from '@/components/ObjectiveQualificationEvidence/Record/DeleteQualificationRecordModal.vue'
import LinkEvidenceWithQualificationRecord from '@/components/ObjectiveQualificationEvidence/Record/LinkEvidence.vue'
import GenericAssociator from '@/components/Forms/GenericAssociator.vue'
import ComponentAllocatedListItem from '@/components/Forms/ComponentAllocatedListItem.vue'
import coreService from '@/libs/api-services/core-service'

export default {
  components: {
    ComponentAllocatedListItem,
    GenericAssociator,
    BRow,
    BCol,
    Associator,
    ListGroupEntity,
    ListGroupReleases,
    RecordListGroupItem,
    CreateQualificationRecordModal,
    UpdateQualificationRecordModal,
    DeleteQualificationRecordModal,
    LinkEvidenceWithQualificationRecord,
  },
  directives: { Ripple },
  data() {
    return {
      id: '',
      name: '',
      acronym: '',
      abstract: false,
      multiplicity: '',
      stereotype_selected: [],
      description: '',
      validity: 'Valid',
      all_interfaces: [],
      context_data: { attributes: {} },
      fields: {
        selected_performers: [],
        selected_objectives: [],
        selected_standards: [],
        selected_enablers: [],
        selected_beneficiaries: [],
        selected_functions: [],
        selected_interfaces: [],
        selected_allocations: [],
        selected_releases: [],
        associated_records: [],
      },
      associators: [
        { singular: 'Performer', label: 'Actors/Performers' },
        { singular: 'Enabler', label: 'Enablers' },
        {
          singular: 'Beneficiary',
          label: 'Beneficiaries',
          plural: 'beneficiaries',
          leftOpts: 'performers',
        },
        { singular: 'Objective', label: 'Objectives' },
        { singular: 'Standard', label: 'Related Standards/References' },
        {
          singular: 'Resource',
          label: 'Entities',
          plural: 'allocations',
          leftOpts: 'resources',
        },
        { singular: 'Function', label: 'Functions' },
        { singular: 'Interface', label: 'Interfaces' },
        { singular: 'Release', label: 'Releases' },
      ],
      selectedQualificationRecordId: '',
    }
  },
  computed: {
    ...mapState({
      selected_requirement: state => state.requirements.selected_requirement,
      associatedRecords: state => state.qualificationRecords.recordsList,
      selectedModel: state => state.model,
    }),
    ...mapState({
      performers: state => state.domainModel.performers,
      functions: state => state.domainModel.functions,
      interfaces: state => state.interfaces.interfaces,
      objectives: state => state.domainModel.objectives,
      standards: state => state.domainModel.standards,
      releases: state => state.releases.releases,
      resources: state => state.domainModel.entities,
    }),
    ...mapGetters({
      stereotypes: 'constants/stereotypes',
    }),
  },
  watch: {
    selected_requirement(newVal) {
      this.fillFields(newVal)
    },
  },
  mounted() {
    this.$store.dispatch('domainModel/getComponents')
    this.$store.dispatch('releases/getReleases')
    this.$store.dispatch('interfaces/fetchInterfaces')
    this.fillFields(this.selected_requirement)
  },
  methods: {
    openUpdateQrModal(qrId) {
      this.selectedQualificationRecordId = qrId
      nextTick(() => {
        this.$bvModal.show(`update-qualification-record-${qrId}`)
      })
    },
    openDeleteQrModal(qrId) {
      this.selectedQualificationRecordId = qrId
      nextTick(() => {
        this.$bvModal.show(`delete-qualification-record-${qrId}`)
      })
    },
    openLinkEvidenceModal(qrId) {
      this.selectedQualificationRecordId = qrId
      nextTick(() => {
        this.$bvModal.show(`link-evidence-with-qualification-record-${qrId}`)
      })
    },
    getPlural(obj) {
      return obj?.plural || `${obj.singular.toLowerCase()}s`
    },
    getLeftOpts(obj) {
      return obj?.leftOpts || this.getPlural(obj)
    },
    fillFields(n) {
      const t = this.fields
      t.selected_performers = n.allocations || []
      t.selected_functions = n.functions || []
      t.selected_interfaces = n.interfaces || []
      t.selected_beneficiaries = n.beneficiaries || []
      t.selected_enablers = n.enablers || []
      t.selected_objectives = n.objectives || []
      t.selected_standards = n.standards || []
      t.selected_allocations = n.direct_components || []
      t.selected_releases = n.releases || []

      this.$store.dispatch('qualificationRecords/getRecordList', n.properties.id)
        .then(() => {
          t.associated_records = this.$store.state.qualificationRecords.recordsList
        })
      const params = { model: this.$store.state.model.id }
      this.$http
        .get('/v1/legacy/interfaces', { params })
        .then(({ data }) => {
          this.all_interfaces = data
        })
        .catch(e => console.error(e))
    },
    async fetchSharedModels() {
      return [this.$store.state.model]
      // return [this.$store.state.model, ...await coreService.modelApi.getLinkedModels(this.$store.state.model.id)]
    },
    async fetchComponents(modelId, subtype) {
      await this.$store.dispatch('domainModel/getComponentsForModel', { modelId, subtype: '' })
      if (modelId && this.$store.state.model.id !== modelId) {
        // now returns all components, not just the subtype
        return this.$store.state.domainModel[modelId].components
      }
      return this.$store.state.domainModel.components
    },
    async linkComponent(cpt, allocationType) {
      if (Array.isArray(cpt)) { [cpt] = cpt }
      await coreService.componentApi.createComponentRequirementAllocation(cpt.id, {
        req_id: this.selected_requirement.properties.id,
        justification: cpt.justification,
        allocation_type: allocationType,
      })
    },
    async unlinkComponent(cptId) {
      await coreService.componentApi.deleteComponentRequirementAllocation(cptId, this.selected_requirement.properties.id)
    },
    linkReqEnablers(evt, objs) {
      evt.preventDefault()
      this.fields.selected_enablers = objs.map(x => ({
        id: x.value,
        name: x.text,
      }))
      const payload = {
        entities: this.fields.selected_enablers.map(x => x.id),
        model: this.$store.state.model.id,
        requirement: this.selected_requirement.properties.id,
      }
      coreService
        .post('/v1/legacy/requirements/associate_enablers', payload)
        .then(({ data }) => {
          this.$bvModal.hide('allocate-req-enablers-modal')
        })
        .catch(r => {
          console.error(r)
          this.$bvModal.hide('allocate-req-enablers-modal')
        })
    },
    linkReqBeneficiaries(evt, objs) {
      evt.preventDefault()
      this.fields.selected_beneficiaries = objs.map(x => ({
        id: x.value,
        name: x.text,
      }))
      const payload = {
        entities: this.fields.selected_beneficiaries.map(x => x.id),
        model: this.$store.state.model.id,
        requirement: this.selected_requirement.properties.id,
      }
      coreService
        .post('/v1/legacy/requirements/associate_beneficiaries', payload)
        .then(({ data }) => {
          this.$bvModal.hide('allocate-req-beneficiaries-modal')
        })
        .catch(r => {
          console.error(r)
          this.$bvModal.hide('allocate-req-beneficiaries-modal')
        })
    },
    linkReqObjectives(evt, objs) {
      evt.preventDefault()
      this.fields.selected_objectives = objs.map(x => ({
        id: x.value,
        name: x.text,
      }))
      const payload = {
        entities: this.fields.selected_objectives.map(x => x.id),
        model: this.$store.state.model.id,
        requirement: this.selected_requirement.properties.id,
      }
      coreService
        .post('/v1/legacy/requirements/associate_objectives', payload)
        .then(({ data }) => {
          this.$bvModal.hide('allocate-req-objectives-modal')
        })
        .catch(r => {
          console.error(r)
          this.$bvModal.hide('allocate-req-objectives-modal')
        })
    },
    linkReqStandards(evt, objs) {
      evt.preventDefault()
      this.fields.selected_standards = objs.map(x => ({
        id: x.value,
        name: x.text,
      }))
      console.log('ReqAllocation::linkReqStandards: ', this.fields)
      const payload = {
        entities: this.fields.selected_standards.map(x => x.id),
        model: this.$store.state.model.id,
        requirement: this.selected_requirement.properties.id,
      }
      coreService
        .post('/v1/legacy/requirements/associate_references', payload)
        .then(({ data }) => {
          this.$bvModal.hide('allocate-req-standards-modal')
        })
        .catch(r => {
          console.error(r)
          this.$bvModal.hide('allocate-req-standards-modal')
        })
    },
    linkReqAllocations(evt, objs) {
      evt.preventDefault()
      this.fields.selected_allocations = objs.map(x => ({
        id: x.value,
        name: x.text,
      }))
      console.log('ReqAllocation::linkReqAllocations: ', this.fields)
      const payload = {
        entities: this.fields.selected_allocations.map(x => x.id),
        model: this.$store.state.model.id,
        requirement: this.selected_requirement.properties.id,
      }
      coreService
        .post('/v1/legacy/requirements/associate_resources', payload)
        .then(({ data }) => {
          this.$bvModal.hide('allocate-req-allocations-modal')
        })
        .catch(r => {
          console.error(r)
          this.$bvModal.hide('allocate-req-allocations-modal')
        })
    },
    linkReqFunctions(evt, objs) {
      evt.preventDefault()
      this.fields.selected_functions = objs.map(x => ({
        id: x.value,
        name: x.text,
      }))
      console.log('ReqAllocation::linkReqFunctions: ', this.fields)
      const payload = {
        entities: this.fields.selected_functions.map(x => x.id),
        model: this.$store.state.model.id,
        requirement: this.selected_requirement.properties.id,
      }
      coreService
        .post('/v1/legacy/requirements/associate_functions', payload)
        .then(({ data }) => {
          this.$bvModal.hide('allocate-req-functions-modal')
        })
        .catch(r => {
          console.error(r)
          this.$bvModal.hide('allocate-req-functions-modal')
        })
    },
    linkReqInterfaces(evt, objs) {
      evt.preventDefault()
      this.fields.selected_interfaces = objs.map(x => ({
        id: x.value,
        name: x.text,
      }))
      console.log('ReqAllocation::linkReqInterfaces: ', this.fields)
      const payload = {
        interfaces: this.fields.selected_interfaces.map(x => x.id),
        model: this.$store.state.model.id,
        requirement: this.selected_requirement.properties.id,
      }
      coreService
        .post('/v1/legacy/requirements/associate_interfaces', payload)
        .then(({ data }) => {
          this.$bvModal.hide('allocate-req-interfaces-modal')
        })
        .catch(r => {
          console.error(r)
          this.$bvModal.hide('allocate-req-interfaces-modal')
        })
    },
    linkReqReleases(evt, objs) {
      evt.preventDefault()
      this.fields.selected_releases = objs.map(x => ({
        id: x.value,
        name: x.text,
      }))
      console.log('ReqAllocation::linkReqReleases: ', this.fields)
      const payload = {
        releases: this.fields.selected_releases.map(x => x.id),
        model: this.$store.state.model.id,
        requirement: this.selected_requirement.properties.id,
      }
      coreService
        .post('/v1/legacy/requirements/associate_releases', payload)
        .then(({ data }) => {
          this.$bvModal.hide('allocate-req-release-modal')
        })
        .catch(r => {
          console.error(r)
          this.$bvModal.hide('allocate-req-release-modal')
        })
    },
  },
}
</script>
