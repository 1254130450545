<template>
  <b-modal
    id="copy-requirement-modal"
    :title="`Copy Requirement ${sourceRequirement.properties.display_id}`"
    size="lg"
    class="p-0"
    lazy
    no-close-on-esc
    no-close-on-backdrop
    @show="onShow"
    @ok.prevent="onSubmit"
  >
    <div>
      <h5 class="text-muted">
        Copying Requirement {{ sourceRequirement.properties.display_id }} <span class="font-small-1">(Scrollable)</span>
      </h5>
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="scroll-area-idea-entities"
        style="max-height: 10rem"
      >
        <div class="font-small-2 ml-1" v-sanitized-html="sourceRequirement.properties.object_text" />
      </vue-perfect-scrollbar>

      <h6 class="mt-2 text-primary">
        1. Select target Specification to Copy to
      </h6>
      <SpecificationPicker v-model="targetSpecification" />

      <h6 class="mt-2 text-primary">
        2. Select Target Requirement
      </h6>
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="scroll-area-idea-entities"
        style="max-height: 20rem"
      >
        <b-table
          responsive
          striped
          hover
          selectable
          show-empty
          select-mode="single"
          class="position-relative"
          :fields="requirementTableFields"
          :items="requirementsList"
          @row-selected="onRowSelected"
        />
      </vue-perfect-scrollbar>

      <div class="d-inline-flex w-100 justify-content-around">
        <div>
          <b-form-checkbox v-model="includeChildren" name="check-button" switch>
            Include all children
          </b-form-checkbox>
        </div>
        <div>
          <b-form-checkbox v-model="traceToOriginal" name="check-button" switch>
            Create trace to original
          </b-form-checkbox>
        </div>
      </div>

      <h6 class="mt-2 text-primary">
        3. Relationships to other objects
      </h6>
      <b-form>
        <b-container>
          <p>Copy links to...</p>
          <b-row>
            <b-col>
              <b-form-checkbox v-model="copyLinksComponents" name="check-button" switch>
                Components
              </b-form-checkbox>
              <b-form-checkbox v-model="copyLinksInterfaces" name="check-button" switch>
                Interfaces
              </b-form-checkbox>
              <b-form-checkbox v-model="copyLinksBehaviourNodes" name="check-button" switch>
                Behaviour Nodes
              </b-form-checkbox>
              <b-form-checkbox v-model="copyLinksReleases" name="check-button" switch>
                Releases
              </b-form-checkbox>
            </b-col>
            <b-col>
              <b-form-checkbox v-model="copyLinksIssues" name="check-button" switch>
                Issues
              </b-form-checkbox>
              <b-form-checkbox v-model="copyLinksNotes" name="check-button" switch>
                Notes
              </b-form-checkbox>
              <b-form-checkbox v-model="copyLinksTest" name="check-button" switch>
                Tests
              </b-form-checkbox>
              <b-form-checkbox v-model="copyLinksTraces" name="check-button" switch>
                Other Requirements (Traces)
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-container>
      </b-form>
      <h6 class="mt-2 text-primary">
        4. Relationship to Target Requirement
      </h6>
      <div class="mt-50 ml-2">
        <b-form-radio-group v-slot="{ ariaDescribedby }">
          <b-form-radio v-model="relationToTarget" :aria-describedby="ariaDescribedby" name="some-radios" value="before">
            Before
          </b-form-radio>
          <b-form-radio v-model="relationToTarget" :aria-describedby="ariaDescribedby" name="some-radios" value="after">
            After
          </b-form-radio>
          <b-form-radio v-model="relationToTarget" :aria-describedby="ariaDescribedby" name="some-radios" value="child">
            Child
          </b-form-radio>
        </b-form-radio-group>
      </div>

      <hr>
      <h5 v-if="targetRequirement" class="text-center mt-1">
        <span class="text-primary">Copy</span>
        requirement
        <span class="text-primary"> {{ sourceRequirement.properties.display_id }} </span>
        <span v-if="includeChildren === true">and all child requirements</span>
        <span class="text-danger text-uppercase"> - {{ relationToTarget === 'child' ? 'as a child below' : relationToTarget }} - </span>
        <span class="text-primary">{{ targetRequirement.display_id }}</span>
      </h5>
    </div>

    <!-- Buttons -->
    <template v-slot:modal-footer="{ ok, cancel}">
      <b-button
        :disabled="loading"
        variant="outline-secondary"
        @click="cancel"
      >
        Discard
      </b-button>
      <b-button variant="success" :disabled="loading || !targetRequirement" @click="onSubmit">
        <span v-if="loading">
          <b-spinner small type="grow" />
          Copying...
        </span>

        <span v-else>
          Copy Requirement
        </span>
      </b-button>
    </template>
    <!-- ./Buttons -->
  </b-modal>
</template>

<script>
import store from '@/store'
import coreService from '@/libs/api-services/core-service'
import { computed, ref, watch } from '@vue/composition-api'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import SpecificationPicker from '@/components/Specifications/SpecificationPicker.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'CopyRequirementsModal',
  components: {
    VuePerfectScrollbar,
    SpecificationPicker,
  },
  setup(props, context) {
    const loading = ref(false)
    const perfectScrollbarSettings = { maxScrollbarLength: 60 }
    const requirementTableFields = [
      { key: 'section', label: 'Section' },
      { key: 'display_id', label: 'Display ID' },
      { key: 'priority', label: 'Priority' },
    ]

    const sourceRequirement = computed(() => store.state.requirements.selected_requirement)
    const targetSpecification = ref(null)
    const targetRequirement = ref(null)
    const relationToTarget = ref('after')
    const includeChildren = ref(true)
    const traceToOriginal = ref(false)
    const copyLinksComponents = ref(true)
    const copyLinksBehaviourNodes = ref(true)
    const copyLinksInterfaces = ref(true)
    const copyLinksReleases = ref(true)
    const copyLinksIssues = ref(true)
    const copyLinksNotes = ref(true)
    const copyLinksTest = ref(true)
    const copyLinksTraces = ref(true)

    const requirementsList = ref([])
    watch(targetSpecification, val => {
      requirementsList.value = []
      if (targetSpecification.value !== null) {
        coreService
          .get(`/v1/legacy/specifications/${targetSpecification.value.id}/requirements`)
          .then(({ data }) => {
            Object.entries(data).forEach(entry => {
              requirementsList.value.push(entry[1])
            })
          })
      }
    })

    const onShow = () => {
      targetSpecification.value = null
      targetRequirement.value = null
      relationToTarget.value = 'after'
      includeChildren.value = true
      traceToOriginal.value = false
    }

    const onRowSelected = rows => {
      targetRequirement.value = rows[0]?.id ? rows[0] : null
    }

    const onSubmit = () => {
      loading.value = true
      // eslint-disable-next-line camelcase
      const copy_relationship_types = []
      if (copyLinksComponents.value) copy_relationship_types.push('ALLOCATED_TO:Component')
      if (copyLinksBehaviourNodes.value) copy_relationship_types.push('ALLOCATED_TO:BehaviourNode')
      if (copyLinksInterfaces.value) copy_relationship_types.push('ALLOCATED_TO:Interface')
      if (copyLinksReleases.value) copy_relationship_types.push('ALLOCATED_TO:Release')
      if (copyLinksIssues.value) copy_relationship_types.push('HAS_ISSUE')
      if (copyLinksNotes.value) copy_relationship_types.push('HAS_NOTE')
      if (copyLinksTest.value) copy_relationship_types.push('TESTED_BY')
      if (copyLinksTraces.value) copy_relationship_types.push('TRACED_TO')
      const payload = {
        requirement_ids: [sourceRequirement.value.properties.id],
        target_relation_type: relationToTarget.value,
        target_requirement: targetRequirement.value.id,
        include_children: includeChildren.value,
        trace_to_source: traceToOriginal.value,
        copy_relationship_types,
      }

      store
        .dispatch('requirements/copyRequirement', payload)
        .then(response => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Copied Requirement',
              text: sourceRequirement.value.properties.display_id,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          context.root.$bvModal.hide('copy-requirement-modal')
          context.emit('ok')
        })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      loading,
      perfectScrollbarSettings,
      requirementTableFields,
      requirementsList,

      sourceRequirement,
      targetSpecification,
      targetRequirement,
      relationToTarget,
      includeChildren,
      traceToOriginal,
      copyLinksComponents,
      copyLinksBehaviourNodes,
      copyLinksInterfaces,
      copyLinksReleases,
      copyLinksIssues,
      copyLinksNotes,
      copyLinksTest,
      copyLinksTraces,
      onShow,
      onRowSelected,
      onSubmit,
    }
  },
}
</script>
