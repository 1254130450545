<template>
  <b-modal
    id="show-hide-columns-modal"
    title="Show / Hide Columns"
    hide-footer
    @shown="fetchColumns"
  >
    <b-alert
      :show="show_hide_field_error"
      dismissible
      variant="danger"
      class="p-50 mb-2"
      @dismissed="show_hide_field_error=false"
    >
      <span class="font-weight-bolder text-capitalize">
        {{ permanently_visible_fields[0] }}
      </span>
      and
      <span class="font-weight-bolder text-capitalize">{{ permanently_visible_fields[1] }}</span>
      are required fields in <span class="font-weight-bolder text-capitalize">{{ mode }}</span> mode.
    </b-alert>

    <div class="w-100 d-inline-flex align-content-center justify-content-between mb-50">
      <span class="font-small-4 mt-25">
        <span class="text-warning font-weight-bolder">{{ visible_columns.length }}</span> columns selected
      </span>
      <b-button
        variant="outline-warning"
        size="sm"
        @click="resetToDefault"
      >
        Reset to default
      </b-button>
    </div>

    <b-form-input
      v-model="searchFilter"
      type="text"
      placeholder="Search..."
      class="mb-50"
    />

    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      style="max-height: 70vh"
    >
      <b-overlay :show="loading_changes">
        <b-table
          :items="filtered_columns"
          hover
          :fields="[{ key: 'label' }]"
          thead-class="d-none"
          @row-clicked="toggleField"
        >
          <template #cell(label)="{ item }">
            <div v-if="visible_columns.includes(item.field)">
              <feather-icon icon="CheckIcon" class="mr-25 text-success" />
              <span class="font-weight-bolder text-capitalize">{{ item.label }}</span>
            </div>
            <div v-else>
              <span class="text-capitalize">{{ item.label }}</span>
            </div>
          </template>
        </b-table>
      </b-overlay>
    </vue-perfect-scrollbar>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import coreService from '@/libs/api-services/core-service'

export default {
  name: 'Column',
  components: {
    VuePerfectScrollbar,
  },
  data() {
    return {
      searchFilter: '',
      columns: [],
      show_hide_field_error: false,
      loading_changes: false,
      perfectScrollbarSettings: {
        maxScrollbarLength: 20,
        wheelPropagation: false,
      },
    }
  },
  computed: {
    ...mapState({
      visible_columns: state => state.requirementsTableLegacy.columns,
      mode: state => state.requirementsTableLegacy.mode,
    }),
    filtered_columns() {
      return this.columns
        .filter(({ label }) => label.toLowerCase().includes(this.searchFilter.toLowerCase()))
        .sort((a, b) => a.label.localeCompare(b.label))
    },
    permanently_visible_fields() {
      return ['display_id', this.mode === 'backlog' ? 'sequence' : 'section']
    },
  },
  methods: {
    mapColumns: item => Object({
      label: item.replaceAll('_', ' '),
      field: item,
    }),
    fetchColumns() {
      const params = {
        model: this.$store.state.model.id,
        specification: (this.$route.params.specId ? this.$route.params.specId : ''),
      }
      coreService
        .get('/v1/legacy/requirements/attributes', { params })
        .then(({ data }) => {
          const { attrs } = data
          this.columns = [...(new Set(
            [...attrs, 'sequence', 'issues', 'tests', 'notes', 'behaviours', 'bns', 'components', 'objectives'],
          ))]
            .map(this.mapColumns)
        })
        .catch(r => console.error(`[RequirementColumn] Failed to fetch column data for table : ${r}`))
    },
    resetToDefault() {
      const defaults = ['section', 'display_id', 'object_text', 'priority']
      // this.columns = defaults.map(this.mapColumns)
      this.$store.dispatch('requirementsTableLegacy/setColumns', defaults).then(() => { this.loading_changes = false })
    },
    toggleField({ field }) {
      this.loading_changes = true
      if (this.permanently_visible_fields.includes(field)) {
        this.show_hide_field_error = true
        this.loading_changes = false
        return
      }
      this.show_hide_field_error = false
      const newCols = this.visible_columns.includes(field) ? this.visible_columns.filter(col => field !== col) : [...this.visible_columns, field]
      this.$store.dispatch('requirementsTableLegacy/setColumns', newCols).then(() => { this.loading_changes = false })
    },
  },
}
</script>
