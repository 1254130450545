<template>
  <b-modal
    id="parse-requirement-modal2"
    :title="`${selected_requirement.properties.display_id} Parse Result`"
    ok-title="Parse Requirement"
    size="xl"
    @hidden="onClose"
    @show="modalOpened()"
  >
    <div v-if="parse_result">
      <LabelWithHoverIcon
        label-string="Requirement:"
        popover-text="This is the Requirements Objective Text."
      />
      <div class="all_entities" v-sanitized-html="parse_result.markup" />
      <hr>

      <!-- Action Blocks -->
      <h4>Actions/Assertions</h4>
      <div v-if="parse_result.actions.length > 0">
        <b-card no-body>
          <b-tabs card>
            <b-tab
              v-for="item in parse_result.actions"
              :key="item.start"
              :title="item.function"
            >
              <b-card-text>
                <!-- Subjects -->
                <div>
                  <b-form-group>
                    <h5 class="mb-1">
                      Actors/Subjects
                    </h5>
                    <div
                      v-for="(subject, index) in item.subjects"
                      :id="subject.subject_props.token_start"
                      :key="subject.subject_props.token_start"
                      class="mb-1"
                    >
                      <b-row>
                        <b-col cols="5">
                          <b-form-group v-if="cptOptions.includes(subject.subject)">
                            <b-form-input
                              v-model="subject.subject"
                              list="subject-input-list"
                              placeholder="Enter Actor/Subject Name..."
                              aria-required="true"
                            />
                          </b-form-group>
                          <b-form-group v-else description="New Entity">
                            <b-form-input
                              v-model="subject.subject"
                              list="subject-input-list"
                              placeholder="Enter Actor/Subject Name..."
                              aria-required="true"
                            />
                          </b-form-group>
                          <b-form-datalist id="subject-input-list" :options="cptOptions" />
                        </b-col>
                        <b-col cols="3">
                          <b-form-group label="#" label-cols="1">
                            <b-form-input
                              v-model="subject.instance"
                              placeholder="Optional Instance Name..."
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="3">
                          <b-form-group label="." label-cols="1">
                            <b-form-input
                              v-model="subject.attribute"
                              placeholder="Optional Attribute Name..."
                            />
                          </b-form-group>
                        </b-col>

                        <b-col
                          cols="1"
                          class="pl-0"
                        >
                          <b-button
                            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                            variant="outline-danger"
                            @click="removeSubject(item.subjects, index)"
                          >
                            <feather-icon
                              icon="XIcon"
                            />
                          </b-button>
                        </b-col>
                      </b-row>

                    </div>

                    <b-row>
                      <b-button
                        v-if="item.subjects.length > 0"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="outline-primary"
                        size="sm"
                        @click="repeatSubject(item.subjects)"
                      >
                        <feather-icon
                          icon="PlusIcon"
                          class="mr-25"
                        />
                        <span>Add Actor/Subject</span>
                      </b-button>
                      <b-button
                        v-else
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="outline-danger"
                        size="sm"
                        @click="repeatSubject(item.subjects)"
                      >
                        <feather-icon
                          icon="PlusIcon"
                          class="mr-25"
                        />
                        <span>Add Actor/Subject *</span>
                      </b-button>
                    </b-row>
                  </b-form-group>
                </div> <!-- Subjects -->
                <!-- Action -->
                <b-row>
                  <b-col cols="3">
                    <b-form-group>
                      <h5>Action Type</h5>
                      <b-form-select id="bn-type-select" v-model="item.action_type" :options="actionTypeOpts" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="3">
                    <b-form-group>
                      <h5>Action Name</h5>
                      <b-form-input
                        id="action-input"
                        v-model="item.action"
                        type="text"
                        placeholder="Enter action name..."
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <h5 class="mb-1">
                        Action Qualifiers/Constraints
                      </h5>
                      <div
                        v-for="(qual, ind) in item.qualification"
                        :id="ind"
                        :key="ind"
                        class="mb-1"
                      >
                        <b-row>
                          <b-col>
                            <b-form-input
                              v-model="qual.relation"
                              placeholder="Enter Action Qualification..."
                            />
                          </b-col>
                          <b-col
                            cols="1"
                            class="pl-0"
                          >
                            <b-button
                              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                              variant="outline-danger"
                              class="h-100"
                              size="sm"
                              @click="removeQual(item.qualification, index)"
                            >
                              <feather-icon
                                icon="XIcon"
                              />
                            </b-button>
                          </b-col>
                        </b-row>

                      </div>

                      <b-row>
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="outline-primary"
                          size="sm"
                          @click="repeatQual(item.qualification)"
                        >
                          <feather-icon
                            icon="PlusIcon"
                            class="mr-25"
                          />
                          <span>Add Action Qualification</span>
                        </b-button>
                      </b-row>
                    </b-form-group>
                  </b-col>
                </b-row> <!-- Action -->
                <hr>
                <!-- Objects -->
                <div>
                  <b-form-group>
                    <h5 class="mb-1">
                      Objects
                    </h5>
                    <div
                      v-for="(object, index) in item.objects"
                      :id="object.object_props.token_start"
                      :key="object.object_props.token_start"
                      class="mb-1"
                    >
                      <b-row>
                        <b-col cols="2">
                          <b-form-input
                            v-model="object.preposition"
                            placeholder="Preposition"
                          />
                        </b-col>
                        <b-col cols="4">
                          <b-form-group v-if="cptOptions.includes(object.object)">
                            <b-form-input
                              v-model="object.object"
                              list="object-input-list-parse"
                              placeholder="Object Name"
                            />
                          </b-form-group>
                          <b-form-group v-else description="New Entity">
                            <b-form-input
                              v-model="object.object"
                              list="object-input-list-parse"
                              placeholder="Object Name"
                            />
                          </b-form-group>
                          <b-form-datalist id="object-input-list-parse" :options="cptOptions" />
                        </b-col>
                        <b-col cols="2">
                          <b-form-group label="#" label-cols="1">
                            <b-form-input
                              v-model="object.instance"
                              placeholder="Instance Name"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="3">
                          <b-form-group label="." label-cols="1">
                            <b-form-input
                              v-model="object.attribute"
                              placeholder="Object Attribute"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="1"
                          class="pl-0"
                        >
                          <b-button
                            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                            variant="outline-danger"
                            @click="removeObject(item.objects, index)"
                          >
                            <feather-icon
                              icon="XIcon"
                            />
                          </b-button>
                        </b-col>

                      </b-row>
                    </div>

                    <b-row>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="outline-primary"
                        size="sm"
                        @click="repeatObject(item.objects)"
                      >
                        <feather-icon
                          icon="PlusIcon"
                          class="mr-25"
                        />
                        <span>Add Object</span>
                      </b-button>
                    </b-row>
                  </b-form-group>
                </div> <!-- Objects -->
                <hr>
                <b-row>
                  <b-col>
                    <!-- Enablers -->
                    <div>
                      <b-form-group>
                        <h5 class="mb-1">
                          Enablers
                        </h5>
                        <div
                          v-for="(enblr, index) in item.enablers"
                          :id="enblr.enabler_props.token_start"
                          :key="enblr.enabler_props.token_start"
                          class="mb-1"
                        >
                          <b-row>
                            <b-col>
                              <b-form-input
                                v-model="enblr.enabler"
                                list="enblr-input-list"
                                placeholder="Enabler Name"
                              />
                              <b-form-datalist id="enblr-input-list" :options="cptOptions" />

                            </b-col>
                            <b-col
                              cols="1"
                              class="pl-0"
                            >
                              <b-button
                                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                variant="outline-danger"
                                class="h-100"
                                size="sm"
                                @click="removeEnabler(item.enablers, index)"
                              >
                                <feather-icon
                                  icon="XIcon"
                                />
                              </b-button>
                            </b-col>

                          </b-row>
                        </div>

                        <b-row>
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="outline-primary"
                            size="sm"
                            @click="repeatEnabler(item.enablers)"
                          >
                            <feather-icon
                              icon="PlusIcon"
                              class="mr-25"
                            />
                            <span>Add Enabler</span>
                          </b-button>
                        </b-row>
                      </b-form-group>
                    </div> <!-- Enablers -->
                  </b-col>
                  <b-col>
                    <!-- Beneficiaries -->
                    <div>
                      <b-form-group>
                        <h5 class="mb-1">
                          Beneficiaries
                        </h5>
                        <div
                          v-for="(ben, index) in item.beneficiaries"
                          :id="ben.beneficiary_props.token_start"
                          :key="ben.beneficiary_props.token_start"
                          class="mb-1"
                        >
                          <b-row>
                            <b-col>
                              <b-form-input
                                v-model="ben.beneficiary"
                                list="ben-input-list"
                                placeholder="Beneficiary Name"
                              />
                              <b-form-datalist id="ben-input-list" :options="cptOptions" />

                            </b-col>
                            <b-col
                              cols="1"
                              class="pl-0"
                            >
                              <b-button
                                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                variant="outline-danger"
                                class="h-100"
                                size="sm"
                                @click="removeBeneficiary(item.beneficiaries, index)"
                              >
                                <feather-icon
                                  icon="XIcon"
                                />
                              </b-button>
                            </b-col>

                          </b-row>
                        </div>

                        <b-row>
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="outline-primary"
                            size="sm"
                            @click="repeatBeneficiary(item.beneficiaries)"
                          >
                            <feather-icon
                              icon="PlusIcon"
                              class="mr-25"
                            />
                            <span>Add Beneficiary</span>
                          </b-button>
                        </b-row>
                      </b-form-group>
                    </div> <!-- Beneficiaries -->
                  </b-col>
                </b-row>

              </b-card-text>
            </b-tab> <!-- Action Group -->
          </b-tabs>
        </b-card>
      </div>

      <div>
        <b-row>
          <!-- Non-Action Things -->
          <b-col>
            <b-form-group>
              <h5 class="mb-1">
                Other Entities to Associate
              </h5>
              <div
                v-for="(thg, index) in otherThings"
                :id="index"
                :key="index"
                class="mb-1"
              >
                <b-row>
                  <b-col>
                    <b-form-input
                      v-model="thg.entity"
                      list="thg-input-list"
                      placeholder="Enter Entity Name..."
                    />
                    <b-form-datalist id="thg-input-list" :options="cptOptions" />

                  </b-col>
                  <b-col
                    cols="1"
                    class="pl-0"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="h-100"
                      size="sm"
                      @click="removeThing(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                      />
                    </b-button>
                  </b-col>

                </b-row>
              </div>

              <b-row>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  size="sm"
                  @click="repeatThing()"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-25"
                  />
                  <span>Add Entity</span>
                </b-button>
              </b-row>
            </b-form-group>
          </b-col><!-- Other Things -->
          <b-col> <!-- BTS -->
            <div>
              <LabelWithHoverIcon
                label-string="Generate Behaviour into:"
                popover-text="Select Behaviour Trees into which any behaviour will be generated."
              />
              <b-form-select v-model="parse_result.bts" :options="bts.map(bt => Object({value:bt.id, text: bt.name}))" :select-size="4" />
            </div>
          </b-col>
        </b-row>

      </div>

      <!-- Issues -->
      <div>
        <b-form-group>
          <h5 class="mb-1">
            Potential Issues Identified
          </h5>
          <div
            v-for="(iss, index) in parse_result.issues"
            :id="index"
            :key="index"
            class="mb-1"
          >
            <b-row>
              <b-col cols="4">
                <b-form-textarea
                  id="IssName"
                  v-model="iss.name"
                  placeholder="Enter Short Name for Issue..."
                  rows="4"
                />
              </b-col>
              <b-col cols="5">
                <b-form-textarea
                  id="issDetails"
                  v-model="iss.description"
                  placeholder="Describe the Issue..."
                  rows="4"
                />
              </b-col>
              <b-col cols="2">
                <b-form-group description="Classification">
                  <b-form-select
                    id="add_issue_select_1"
                    v-model="iss.classification"
                    :options="issueClassifications"
                  />
                </b-form-group>
                <b-form-group description="Severity">
                  <b-form-select
                    id="add_issue_select_2"
                    v-model="iss.severity"
                    :options="issueSeverities"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="1"
                class="pl-0"
              >
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="h-100"
                  size="sm"
                  @click="removeIssue(parse_result.issues, index)"
                >
                  <feather-icon
                    icon="XIcon"
                  />
                </b-button>
              </b-col>

            </b-row>
          </div>

          <b-row>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              size="sm"
              @click="repeatIssue(parse_result.issues)"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>Add Issue</span>
            </b-button>
          </b-row>
        </b-form-group>
      </div> <!-- Issues -->
    </div>
    <b-overlay v-else show opacity="0.3">
      <template v-slot:overlay>
        <h1 style="display: inline-block">
          Parsing {{ requirement_context.properties.display_id }}
        </h1> <b-spinner />
      </template>
      <div style="height: 50vh;" />
    </b-overlay>
    <template v-slot:modal-footer="{ cancel }">
      <b-button v-if="!parsing" variant="primary" @click="makeAssociations()">
        Make Associations
      </b-button>
      <b-button v-else variant="primary">
        Associating Parse Results <b-spinner type="grow" small />
      </b-button>
      <b-button variant="outline-primary">
        Re-Parse Requirement
      </b-button>
      <b-button @click="cancel()">
        Cancel
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import coreService from '@/libs/api-services/core-service'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import LabelWithHoverIcon from '@/components/Forms/LabelWithHoverIcon.vue'

export default {
  name: 'Parse',
  directives: {
    Ripple,
  },
  components: {
    LabelWithHoverIcon,
  },
  props: {
    requirementId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      local_requirement: null,
      parse_result: null,
      issues_selected: [],
      behaviour_to_generate: [],
      name_map: {},
      object_text: '',
      aliases: {},
      parsing: false,
      issues: [],
      key: 0,
      allCpts: [],
      cptOptions: [],
      otherThings: [],
      actionTypeOpts: [
        {
          value: 'Event',
          text: 'Event',
        },
        {
          value: 'State',
          text: 'State',
        },
        {
          value: 'Assertion',
          text: 'Assertion',
        },
      ],
    }
  },
  computed: {
    ...mapState({
      selected_requirement: state => state.requirements.selected_requirement,
      bts: state => state.behaviours.behaviourViews,
    }),
    ...mapGetters({
      issueClassifications: 'constants/issueClassifications',
      issueSeverities: 'constants/issueSeverities',
    }),
    requirement_context() {
      if (this.requirementId) {
        // this.$http.get(`/v1/legacy/requirements/context/${}`)
        return this.requirementId
      }
      console.log('Opening with: ', this.selected_requirement)
      return this.selected_requirement
    },
  },
  methods: {
    getMatches(token) {
      if (this.parse_result.thing_dict[token]) {
        const matchList = this.parse_result.thing_dict[token].matches.map(m => m.name)
        this.allCpts.forEach(c => {
          if (!(matchList.includes(c.text))) {
            matchList.push(c.text)
          } else {
            console.log('OUT: ', c)
          }
        })
        console.log('List: ', matchList)
        return matchList
      }
      return this.allCpts
    },
    get_qualifications(token) {
      if (this.parse_result.thing_dict[token]) {
        return this.parse_result.thing_dict[token].qualifications
      }
      return []
    },
    forceAssociate({
      name, item, c_id, type,
    }) {
      // update the aliases of to_assoc to include orig
      const toAssociate = name
      const orig = item.name
      console.log('Replace: ', orig, toAssociate)
      if (orig !== toAssociate) {
        if (this.aliases[toAssociate]) {
          console.debug(toAssociate, orig)
          this.aliases[toAssociate].push(orig)
        } else {
          this.aliases[toAssociate] = [orig]
        }
        this.name_map[orig] = toAssociate
      }
      if (type !== 'Function') {
        // substitute to_assoc for orig in text
        const re = new RegExp(`\\b${orig}\\b`, 'ig')
        const replaceWith = `<b>${toAssociate}</b>`
        this.object_text = this.object_text.replace(re, replaceWith)
      }
      document.getElementById(c_id).value = (toAssociate)
    },
    makeAssociations() {
      this.parsing = true
      /**
       * Pre-prep
      * */
      this.parse_result.other_things = this.otherThings
      console.log('Do Parse Associations with: ', this.parse_result)
      // Call the graph creation api endpoint with results
      coreService
        .post(`/v1/legacy/requirements/${this.selected_requirement.properties.id}/associate_parse2`, { parse: this.parse_result })
        .then(({ data }) => {
          console.log('Created: ', data)
          this.$bvModal.hide('parse-requirement-modal2')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Requirement Parsed',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$emit('parsed')
        }).catch(e => {
          this.$bvModal.hide('parse-requirement-modal2')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Problems with Parse - Check Results',
              icon: 'AlertTriangleIcon',
              variant: 'warning',
            },
          })
          this.$emit('parsed')
        })
    },
    onClose() {
      this.parse_result = null
    },
    modalOpened() {
      this.parsing = false
      this.$store.dispatch('behaviours/getAllBehaviourTrees')
      this.$store.dispatch('constants/fetchConstants')
      const params = { model: this.$store.state.model.id }
      coreService
        .get(`/v1/legacy/requirements/${this.selected_requirement.properties.id}/parse2`, { params })
        .then(({ data }) => {
          console.log('Parse Results: ', data)
          this.object_text = this.selected_requirement.properties.object_text
          // Don't open the rest of the modal if there was an exception in parsing
          if (data.parse_results.length === 0) {
            this.$bvModal.hide('parse-requirement-modal2')
            this.$emit('parsed')
          } else {
            this.parse_result = data.parse_results
            this.parse_result.original_requirement = data.requirement
            this.otherThings = []
            const used = [...new Set(
              [
                ...this.parse_result.subjects,
                ...this.parse_result.objects,
                ...this.parse_result.enablers,
                ...this.parse_result.beneficiaries,
              ],
            )]
            this.otherThings = this.parse_result.things.filter(t => !used.includes(t.token_start))
          }
        })
      coreService.get('/v1/legacy/domain_model/get_components_simple', { params })
        .then(({ data }) => {
          this.allCpts = data.map(x => ({
            value: x.id,
            text: x.name,
          }))
          this.cptOptions = data.map(x => x.name)
        })
    },
    // -- Utility -- //
    repeatObject(objects) {
      console.log('Objects: ', objects)
      objects.push({
        id: this.nextObjectId += 1,
        object: '',
        preposition: '',
        object_props: { token_start: -1 },
        qualifications: [],
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeObject(objects, index) {
      objects.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    repeatSubject(subjects) {
      subjects.push({
        id: this.nextObjectId += 1,
        subject: '',
        subject_props: { token_start: -1 },
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeSubject(subjects, index) {
      subjects.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    repeatEnabler(enablers) {
      enablers.push({
        id: this.nextObjectId += 1,
        enabler: '',
        enabler_props: { token_start: -1 },
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeEnabler(enablers, index) {
      enablers.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    repeatBeneficiary(beneficiaries) {
      beneficiaries.push({
        id: this.nextObjectId += 1,
        beneficiary: '',
        beneficiary_props: { token_start: -1 },
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeBeneficiaries(beneficiaries, index) {
      beneficiaries.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    repeatQual(quals) {
      quals.push({
        relation: '',
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeQual(quals, index) {
      quals.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    repeatThing() {
      this.otherThings.push({
        id: this.nextObjectId += 1,
        entity: '',
        token_start: -1,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeThing(index) {
      this.otherThings.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    repeatIssue(issues) {
      issues.push({
        id: this.nextObjectId += 1,
        name: '',
        description: '',
        severity: 'Query',
        classification: 'Query',
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeIssue(issues, index) {
      issues.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
  },
}
</script>

<style lang="scss">
.entities {
     line-height: 2;
}

[data-entity] {
     padding: 0.25em 0.35em;
     margin: 0px 0.25em;
     line-height: 1;
     display: inline-block;
     border-radius: 0.25em;
     border: 1px solid;
}

[data-entity]::after {
     box-sizing: border-box;
     content: attr(data-entity);
     font-size: 0.6em;
     line-height: 1;
     padding: 0.35em;
     border-radius: 0.35em;
     text-transform: uppercase;
     display: inline-block;
     vertical-align: middle;
     margin: 0px 0px 0.1rem 0.5rem;
}

[data-entity][data-entity="thing"] {
     background: rgba(142, 125, 255, 0.2);
     border-color: rgb(142, 125, 255);
}

[data-entity][data-entity="thing"]::after {
     background: rgb(142, 125, 255);
}

[data-entity][data-entity="subject"] {
     background: rgba(166, 226, 45, 0.2);
     border-color: rgb(166, 226, 45);
}

[data-entity][data-entity="subject"]::after {
     background: rgb(166, 226, 45);
}

[data-entity][data-entity="object"] {
     background: rgba(67, 198, 252, 0.2);
     border-color: rgb(67, 198, 252);
}

[data-entity][data-entity="object"]::after {
     background: rgb(67, 198, 252);
}

[data-entity][data-entity="enabler"] {
     background: rgba(142, 125, 255, 0.2);
     border-color: rgb(142, 125, 255);
}

[data-entity][data-entity="enabler"]::after {
     background: rgb(142, 125, 255);
}

[data-entity][data-entity="action"] {
     background: rgba(253, 151, 32, 0.2);
     border-color: rgb(253, 151, 32);
}

[data-entity][data-entity="action"]::after {
  background: rgb(253, 151, 32);
}
[data-entity][data-entity="qual"] {
     background: rgba(233, 5, 150, 0.2);
     border-color: rgb(233, 5, 150);
}

[data-entity][data-entity="qual"]::after {
  background: rgb(233, 5, 150);
}

[data-entity][data-entity="prep"] {
     background: rgba(139, 69, 19, 0.2);
     border-color: rgb(139, 69, 19);
}

[data-entity][data-entity="prep"]::after {
     background: rgb(142, 125, 255);
}

body.dark-layout {
  [data-entity] {
     padding: 0.25em 0.35em;
     margin: 0px 0.25em;
     line-height: 1;
     display: inline-block;
     border-radius: 0.25em;
     border: 1px solid;
     color: whitesmoke;
  }

  [data-entity]::after {
       box-sizing: border-box;
       content: attr(data-entity);
       font-size: 0.6em;
       color: black;
       line-height: 1;
       padding: 0.35em;
       border-radius: 0.35em;
       text-transform: uppercase;
       display: inline-block;
       vertical-align: middle;
       margin: 0px 0px 0.1rem 0.5rem;
  }

  [data-entity][data-entity="thing"] {
       background: rgba(142, 125, 255, 0.2);
       border-color: rgb(142, 125, 255);
  }

  [data-entity][data-entity="thing"]::after {
       background: rgb(142, 125, 255);
  }

  [data-entity][data-entity="subject"] {
       background: rgba(166, 226, 45, 0.2);
       border-color: rgb(166, 226, 45);
  }

  [data-entity][data-entity="subject"]::after {
       background: rgb(166, 226, 45);
  }

  [data-entity][data-entity="object"] {
       background: rgba(67, 198, 252, 0.2);
       border-color: rgb(67, 198, 252);
  }

  [data-entity][data-entity="object"]::after {
       background: rgb(67, 198, 252);
  }

  [data-entity][data-entity="enabler"] {
       background: rgba(142, 125, 255, 0.2);
       border-color: rgb(142, 125, 255);
  }

  [data-entity][data-entity="enabler"]::after {
       background: rgb(142, 125, 255);
  }

  [data-entity][data-entity="action"] {
       background: rgba(253, 151, 32, 0.2);
       border-color: rgb(253, 151, 32);
  }

  [data-entity][data-entity="action"]::after {
    background: rgb(253, 151, 32);
  }
  [data-entity][data-entity="qual"] {
     background: rgba(233, 5, 150, 0.2);
     border-color: rgb(233, 5, 150);
  }

  [data-entity][data-entity="qual"]::after {
    background: rgb(233, 5, 150);
  }
}
</style>
