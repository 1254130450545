<template>
  <b-modal
    v-if="dragEvent"
    id="drop-section-modal"
    :title="`Move Requirement: ${selected.displayId}`"
    centered
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
    @close="cancel"
  >
    <!-- Sibling -->
    <b-row>
      <b-col>
        <p>Move to be a <span class="text-primary font-weight-bolder">sibling</span> of {{ previous.displayId }}</p>
        <ul class="list-unstyled ml-2">
          <li>- {{ previous.section }} - {{ previous.displayId }}</li>
          <li class="text-primary font-weight-bolder">
            - {{ getSection('sibling') }} - {{ selected.displayId }}
          </li>
          <!--<li><span class="text-dark">...</span></li>-->
        </ul>
        <!--<b-button variant="outline-success" block @click="moveSection('sibling')">-->
        <!--  Make Sibling (<span class="font-weight-bolder">{{ getSection('sibling') }}</span>)-->
        <!--</b-button>-->
      </b-col>
    </b-row>
    <!-- ./Sibling -->

    <hr class="my-2">

    <!-- Child -->
    <b-row>
      <b-col>
        <p>Move to be a <span class="text-primary font-weight-bolder">child</span> of {{ previous.displayId }}</p>
        <ul class="list-unstyled ml-2">
          <li>
            - {{ previous.section }} - {{ previous.displayId }}
            <ul class="pl-1 list-unstyled">
              <!--<li><span class="text-dark">...</span></li>-->
              <li class="text-primary font-weight-bolder">
                - {{ getSection('child') }} - {{ selected.displayId }}
              </li>
            </ul>
          </li>
          <!--<li><span class="text-dark">...</span></li>-->
        </ul>
        <!--<b-button variant="outline-success" block @click="moveSection('child')">-->
        <!--  Make Child (<span class="font-weight-bolder">{{ getSection('child') }}</span>)-->
        <!--</b-button>-->
      </b-col>
    </b-row>
    <!-- ./Child -->
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex'
import coreService from '@/libs/api-services/core-service'

export default {
  name: 'DropSection',
  props: {
    dragEvent: {
      type: CustomEvent,
      default: null,
    },
  },
  computed: {
    selected() {
      return this.dragEvent.item.dataset
    },
    previous() {
      return this.dragEvent.item.previousSibling.dataset
    },
    next() {
      return this.dragEvent.item.nextSibling.dataset
    },
  },
  methods: {
    getSection(placement = 'child') {
      if (placement === 'child') {
        return `${this.previous.section}.new`
      }
      const previousSplit = this.previous.section.split('.')
      // eslint-disable-next-line radix
      previousSplit[previousSplit.length - 1] = parseInt(previousSplit[previousSplit.length - 1]) + 1
      return previousSplit.join('.')
    },
    cancel() {
      console.log(this.dragEvent)
    },
    getData(DOMRow) {
      return Object(DOMRow.children[0].childNodes[0].dataset)
    },
  },
}
</script>

<style scoped>

</style>
